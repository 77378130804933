define("discourse/plugins/paczki-swapcrypto/discourse/templates/crypto", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="swapcrypto">
      <h1 class="swapcrypto_crypto_title">{{{i18n "swapcrypto.crypto.title"}}}</h1>
      <h4 class="swapcrypto_crypto_text">{{{i18n "swapcrypto.crypto.text"}}}</h4>
      <div class="swapcrypto_crypto_widget">
          {{{i18n "swapcrypto.crypto.widget"}}}
      </div>
      <div class="swapcrypto_crypto_bottom_text">
          {{{i18n "swapcrypto.crypto.bottom_text"}}}
      </div>
  </div>
  */
  {
    "id": "iFYSKS9R",
    "block": "[[[10,0],[14,0,\"swapcrypto\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"swapcrypto_crypto_title\"],[12],[2,[28,[37,0],[\"swapcrypto.crypto.title\"],null]],[13],[1,\"\\n    \"],[10,\"h4\"],[14,0,\"swapcrypto_crypto_text\"],[12],[2,[28,[37,0],[\"swapcrypto.crypto.text\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"swapcrypto_crypto_widget\"],[12],[1,\"\\n        \"],[2,[28,[37,0],[\"swapcrypto.crypto.widget\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"swapcrypto_crypto_bottom_text\"],[12],[1,\"\\n        \"],[2,[28,[37,0],[\"swapcrypto.crypto.bottom_text\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\"]]",
    "moduleName": "discourse/plugins/paczki-swapcrypto/discourse/templates/crypto.hbs",
    "isStrictMode": false
  });
});