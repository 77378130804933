define("discourse/plugins/paczki-swapcrypto/discourse/initializers/swapcrypto", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "paczki-swapcrypto",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        api.onPageChange(url => {
          if (window.location.pathname == "/swap") {
            document.querySelector("body").classList.add("page-swap");
          } else {
            document.querySelector("body").classList.remove("page-swap");
          }
          if (window.location.pathname == "/crypto") {
            document.querySelector("body").classList.add("page-crypto");
          } else {
            document.querySelector("body").classList.remove("page-crypto");
          }
        });
      });
    }
  };
});